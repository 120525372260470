* {
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif !important;
}

body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #fcfcfd !important;
}
