.cm-checkbox {
  span.ant-checkbox-inner {
    background: white;
    height: 20px;
    width: 20px;
    border-radius: 6px;
  }
  .ant-checkbox.ant-checkbox-checked {
    border-radius: 6px;
    overflow: hidden;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    left: 28%;
  }
}
