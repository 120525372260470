.scrolling-right {
  position: fixed;
  left: 0;
  top: 75px;
  z-index: 3;
  button {
    padding: 0;
    svg {
      width: 11px;
      height: 8px;
      path {
        stroke: #353945;
      }
    }
  }
}

.scrolling-left {
  position: fixed;
  right: 0;
  top: 75px;
  z-index: 3;
  button {
    padding: 0;
    svg {
      transform: rotate(180deg);
      width: 11px;
      height: 8px;
      path {
        stroke: #353945;
      }
    }
  }
}

.titles {
  position: fixed;
  top: 56px;
  background: #f4f5f6;
  z-index: 3;
  right: 0;
  left: 0;
  padding: 16px 12px;
  display: flex;
  box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
  ::-webkit-scrollbar {
    display: none;
  }
}

.titles > * {
  display: flex;
  overflow: hidden;
  max-width: calc(100vw - 57px);
  margin: 0 auto;
}

.titles > * > button:not(:last-of-type) {
  margin-right: 4px;
}

.titles > * > button:not(:first-of-type) {
  margin-left: 4px;
}

.titles > * > * {
  max-height: 32px;
  border: none;
  background: none !important;
  color: #353945 !important;
  font-size: 14px;
  line-height: 24px;
  padding: 4px 16px;
}

.titles > * > *:hover {
  background: #e4e4e4 !important;
}

.titles > * > .active {
  background: #353945 !important;
  color: #ffffff !important;
  font-weight: 600;
}

.blocks {
  width: calc(100vw - 2rem);
  margin: 132px auto 0;
}

.blocks .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
  margin-bottom: 4px;
}

.blocks .description {
  color: #373737;
  margin-bottom: 12px;
  font-size: 14px;
}

@font-family: 'Work Sans', sans-serif;@border-radius-base: 20px;@primary-color: #14316D;@info-color: #6ce1f7;@minor-color: #ADB6CC;@link-color: #4395D4;@link-hover-decoration: underline;@label-color: #14316D;@normal-color: #13316D;@font-size-base: 16px;@font-size-lg: 18px;@font-size-xlg: 28px;@font-size-sm: 14px;@border-color-base: #ADB6CC;@form-item-margin-bottom: 19px;@disabled-bg: #F8F9FC;@layout-body-background: #F8F9FC;@avatar-color: #14316D;@avatar-bg: #E9ECF3;@box-border-decoration: 1px solid #e9e9e9;@btn-font-weight: 400;@btn-height-base: 42px;@btn-height-lg: 50px;@btn-height-sm: 34px;@btn-default-color: white;@btn-default-bg: #ADB6CC;@btn-default-border: #98a1b5;@input-height-base: 42px;@input-height-lg: 50px;@input-height-sm: 34px;@input-hover-border-color: #13316D;@table-header-bg: white;@table-footer-bg: white;@table-header-color: #14316D;@table-row-hover-bg: #F8F9FC;@z-index-mobile-menu: 1;@z-index-mobile-menu-header: 2;@z-index-mobile-sidebar: 1;@time-cell-min-width: 120;@time-cell-week-min-width: 40;@time-cell-height: 100;@disabled-time-bg: repeating-linear-gradient(-55deg, #eff2f8, #eff2f8 10px, #f8f9fc 10px, #f8f9fc 20px);@calendar-user-header-height: 50;@prefix-time-height: 40;@time-column-width: 80;@time-column-width-mobile: 40;@calendar-top-offset: 161;@calendar-mobile-top-offset: 110;@calendar-time-cell-width: 60;@calendar-time-cell-mobile-width: 45;