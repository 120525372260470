* {
  scroll-behavior: smooth;
  font-family: 'Poppins', sans-serif !important;
}
body {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #fcfcfd !important;
}
.cm-checkbox span.ant-checkbox-inner {
  background: white;
  height: 20px;
  width: 20px;
  border-radius: 6px;
}
.cm-checkbox .ant-checkbox.ant-checkbox-checked {
  border-radius: 6px;
  overflow: hidden;
}
.cm-checkbox .ant-checkbox-checked .ant-checkbox-inner:after {
  left: 28%;
}
